﻿.c-experience-hero {
	&::before {
		background: transparent url('/content/images/interface/bg/wg-bg-hero-wave.svg') center bottom no-repeat;
		background-size: 100%;
	}

	&::after {
		background: none;
	}
}
