@charset "UTF-8";

// For an explanation of the folder structure and namespacing check the wiki: https://terabyte.atlassian.net/wiki/spaces/TBWS/pages/94670004/CSS+Framework

// Setup
@import "base-theme/theme";
@import "base-theme/config";

// SITE SPECIFIC THEME TO OVERRIDE
@import "../fonts/wg-font-icons/variables";
@import "themes/wg/wg.theme";

// Tools
@import "base-theme/tools/tools.functions";
@import "base-theme/tools/tools.mixins";
@import "base-theme/tools/tools.animations";

// Generic
@import "base-theme/generic/generic.normalize";
@import "base-theme/generic/generic.reset";

// Base
@import "base-theme/base/base.typography";
// To fix bold font used as default/normal font initially
$weight--bold: 600;
@import "base-theme/base/base.links";
@import "base-theme/base/base.forms";

// Plugins
@import "base-theme/plugins/plugins.tablescroll";
@import "base-theme/plugins/plugins.magnific-popup";
@import "base-theme/plugins/plugins.flickity";
@import "base-theme/plugins/plugins.flickity-fade";
@import "base-theme/plugins/plugins.pickaday";
@import "base-theme/plugins/plugins.addtohomescreen";
@import "base-theme/plugins/plugins.juicer";
@import "base-theme/plugins/plugins.ibis";
@import "base-theme/plugins/plugins.jquery-ui";


// Objects
@import "base-theme/objects/object.list";
@import "base-theme/objects/object.control";
@import "base-theme/objects/object.figure";
@import "base-theme/objects/object.pod";
@import "base-theme/objects/object.rotator";
@import "base-theme/objects/object.embed";
@import "base-theme/objects/object.grid";
@import "base-theme/objects/object.btn";
@import "base-theme/objects/object.fake-input";
@import "base-theme/objects/object.input-combo";
@import "base-theme/objects/object.flex-pair";
@import "base-theme/objects/object.icon-wrapper";
@import "base-theme/objects/object.layout";
@import "base-theme/objects/object.grid-layout";
@import "base-theme/objects/object.body-text";
@import "base-theme/objects/object.tile";
@import "base-theme/objects/object.fit";
@import "base-theme/objects/object.loader";

// Controls
@import "base-theme/controls/control.rte";
@import "base-theme/controls/control.form";
@import "base-theme/controls/control.image";
@import "base-theme/controls/control.image-gallery";
@import "base-theme/controls/control.video";
@import "base-theme/controls/control.google-map";
@import "base-theme/controls/control.pods";
@import "base-theme/controls/control.rotator";
@import "base-theme/controls/control.heading";
@import "base-theme/controls/control.listing-stacked";
@import "base-theme/controls/control.listing-tiled";
@import "base-theme/controls/control.filter";
@import "base-theme/controls/control.cta";
@import "base-theme/controls/control.subnav";
@import "base-theme/controls/control.related-items";
@import "base-theme/controls/control.expand-collapse";
@import "base-theme/controls/control.member-login";
@import "base-theme/controls/control.statistics";
@import "base-theme/controls/control.image-feature";
@import "base-theme/controls/control.icon-promos";
@import "base-theme/controls/control.image-cta";
@import "base-theme/controls/control.image-carousel";
@import "base-theme/controls/control.mailchimp";
@import "base-theme/controls/control.highlights";
@import "base-theme/controls/control.faq";
@import "base-theme/controls/control.product-listing";
@import "base-theme/controls/control.resources";
@import "base-theme/controls/control.newsletter";
@import "base-theme/controls/control.dual-cta";
@import "base-theme/controls/control.experience-carousel";
@import "base-theme/controls/control.testimonials-rotator";
@import "base-theme/controls/control.transfer-widget";
@import "base-theme/controls/control.accommodation-carousel";
@import "base-theme/controls/control.highlight-text";


// Macros
@import "base-theme/macros/macro.image";
@import "base-theme/macros/macro.gallery";
@import "base-theme/macros/macro.video";
@import "base-theme/macros/macro.map";
@import "base-theme/macros/macro.quote";
@import "base-theme/macros/macro.related";

// Components
@import "base-theme/components/component.theme";
@import "base-theme/components/component.alert";
@import "base-theme/components/component.breadcrumb";
@import "base-theme/components/component.share";
@import "base-theme/components/component.header";
@import "base-theme/components/component.navigation";
@import "base-theme/components/component.figure";
@import "base-theme/components/component.megamenu";
@import "base-theme/components/component.hero";
@import "base-theme/components/component.footer";
@import "base-theme/components/component.tooltip";
@import "base-theme/components/component.cookie-confirm";
@import "base-theme/components/component.search-result";
@import "base-theme/components/component.event-details";
@import "base-theme/components/component.pagination";
@import "base-theme/components/component.header-notification";
@import "base-theme/components/component.modal";
@import "base-theme/components/component.product-tile";
@import "base-theme/components/component.product-hero";
@import "base-theme/components/component.booking-panel";
@import "base-theme/components/component.basic-filter";
@import "base-theme/components/component.experience-tile";
@import "base-theme/components/component.cta-intro";
@import "base-theme/components/component.experience-hero";
@import "base-theme/components/component.video-gallery";
@import "base-theme/components/component.hamnav";
@import "base-theme/components/component.hero-home";
@import "base-theme/components/component.food-menu";
@import "base-theme/components/component.accommodation-tile";
@import "base-theme/components/component.accommodation-hero";
@import "base-theme/components/component.accommodation";
@import "base-theme/components/component.info-widget";
@import "base-theme/components/component.booking-widget";


// Utility
@import "base-theme/utility/utility.widths";
@import "base-theme/utility/utility.padding";
@import "base-theme/utility/utility.margins";
@import "base-theme/utility/utility.helpers";
@import "base-theme/utility/utility.responsive";

// Print
@import "base-theme/print/print";


// Uncomment the wireframe import to create a clickable wireframe styled site
//@import "base-theme/utility/utility.wireframe";


// Umcomment the debug import to highlight markup errors
//@import "debug/revenge";

// Overrides
@import "themes/wg/utility/wg.utility.icons";
@import "themes/wg/base/wg.base.typography";
@import "themes/wg/components/wg.component.header";
@import "themes/wg/components/wg.component.navigation";
@import "themes/wg/components/wg.component.footer";
@import "themes/wg/components/wg.component.contour";
@import "themes/wg/components/wg.component.hero-home";
@import "themes/wg/components/wg.component.experience-hero";
