﻿/*
- CONTOUR CONTAINER
- CONTOUR HEAD
- CONTOUR BODY
- CONTOUR BODY TEXT
- CONTOUR BODY IMAGE
- CONTOUR BODY EXPLORE
*/



/* CONTOUR CONTAINER */

$contour-peak-height: 65px;

.c-contour {
	position: relative;
}




/* CONTOUR HEAD */

.c-contour__head {
	background: linear-gradient(0deg, #E5F7FD 42.67%, rgba(242, 251, 254, 0.80) 69.95%, rgba(255, 255, 255, 0.00) 98.4%);
	padding-bottom: $contour-peak-height;
	min-height: 300px;
}

.c-contour__head-layout {
	@include bp('large') {
		@include flex-extend;
		align-items: flex-start;
		justify-content: center;
		justify-content: space-between;
	}
}

.c-contour__heading {
	@include font-heading-28b;
	display: block;
	margin-bottom: $lsu;
	text-align: center;

	@supports (border-bottom: var(--supporting-1-a)) {
		position: relative;
		padding-bottom: $bsu;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			width: 80px;
			border-bottom: 4px solid var(--supporting-1-a);
		}
	}

	@include bp('large') {
		margin-right: $lsu;
		text-align: left;

		&::after {
			left: 0;
			transform: translateX(0);
		}
	}
}



/* CONTOUR BODY */

.c-contour-body {
	position: relative;
	background: $brand--alt;
	padding-top: $contour-peak-height;
	padding-bottom: $contour-peak-height * 2;
	color: $white;

	.o-layout-wrapper {
		position: relative;
	}

	&:before {
		position: absolute;
		left: 0;
		top: -90px;
		content: "";
		background: transparent url('/content/images/interface/wg/contour_blue.svg') center bottom no-repeat;
		height: 100px;
		width: 100%;
		display: block;
		background-size: auto 90px;
	}

	@include bp('large') {
		padding-bottom: $contour-peak-height * 2;
	}
}

.c-contour__body-layout {
	@include bp('large') {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		width: 66.66%;
	}
}



/* CONTOUR BODY TEXT */

.c-contour-body-text {
	display: block;
	margin-bottom: $lsu;

	@include font-heading-20;

	> p:last-child {
		margin-bottom: 0;
	}
}



/* CONTOUR BODY IMAGE */

.c-contour-body-image {
	width: 200px;
	aspect-ratio: 1/1;
	margin: 0 auto 52px auto;

	@include bp('large') {
		width: 250px;
		flex: 0 0 250px;
		order: 3;
		margin: 0;
		position: absolute;
		right: $bsu;
	}
}


/* CONTOUR BODY EXPLORE */
.c-contour-body-explore {
}

.c-contour-body-explore__heading {
	display: block;

	@include font-heading-10u;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		margin-left: $bsu;
		margin-top: -3px;
		border-bottom: 4px solid $theme-yellow;
		width: 80px;
	}
}

.c-contour-body-explore__items {
	@include list-reset;
	@include flex-extend;

	@include bp-below('medium') {
		flex-wrap: wrap;
	}

	margin-top: $bsu; // $lsu - $bsu
	margin-left: -$bsu;

	@include bp('medium') {
		margin-left: -$lsu;
		margin-top: $lsu;
	}
}

.c-contour-body-explore__item {
	padding-top: $bsu;
	padding-left: $bsu;

	@include bp('medium') {
		padding-top: 0;
		padding-left: $lsu;
	}
}

.c-contour-body-explore__link {
	@include flex-extend;
	color: $white;
	align-items: center;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-contour-body-explore__image {
	display: block;
	margin-right: $ssu;
	width: 50px;
	flex-grow: 0;
	flex-shrink: 0;
}

.c-contour-body-explore__name {
	display: block;

	@include font-heading-16u;
	text-transform: none;

	@include bp('large') {
		max-width: 130px;
	}
}
