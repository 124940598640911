﻿@mixin font-heading-100 {
	font-family: $heading-font-family;
	font-size: 72px;

	@include bp("medium") {
		font-size: 100px;
	}

	font-weight: $weight--bold;
	line-height: lh(100, 100);
}

@mixin font-heading-20u {
	@include font-heading-20;
	text-transform: uppercase;
}

@mixin font-heading-16u {
	@include font-heading-16;
	text-transform: uppercase;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: lh(18, 14);
}

@mixin font-heading-12b {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(18, 12);
}

@mixin font-heading-10u {
	font-family: $heading-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	text-transform: uppercase;
	line-height: lh(12, 10);
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);

	@include bp("medium") {
		font-size: 20px;
		line-height: lh(36, 28);
	}
}


@mixin font-heading-28b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);

	@include bp("medium") {
		font-size: 28px;
		line-height: lh(36, 28);
	}
}