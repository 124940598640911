﻿.c-header__logo {
	img {
		width: 160px;
	}

	@include bp("large") {
		padding: $ssu;

		img {
			width: 250px;
		}
	}
}
