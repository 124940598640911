.c-navigation__item {
	&[aria-expanded="true"],
	&[aria-current]:not([aria-current="false"]) {
		.c-navigation__link {
			color: $brand--alt;

			&::after {
				border-bottom-color: $brand--alt;
			}
		}
	}
}


.c-navigation__link {
	@include font-heading-14b;
	color: $brand;
}

