﻿$contour-peak-height: 95px;

.c-hero-home {
	margin-bottom: calc(#{$contour-peak-height} / 3);

	@include bp('medium') {
		margin-bottom: calc(#{$contour-peak-height} / 2);
	}
}

.c-hero-home__main {
	.c-contour + & {
		margin-top: -$contour-peak-height * 1.5;
	}

	@include bp('medium') {
		.c-contour + & {
			margin-top: -$contour-peak-height * 2;
		}
	}
}

.c-hero-home__body {
	bottom: auto;
	top: calc(50% - 58px);
	transform: translateY(-50%);
	max-width: 800px;
}

.c-hero-home__heading {
	text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.40);
}

.c-hero-home__icons {
	position: absolute;
	bottom: 0;
	max-width: $layout-wrapper-max-width;
	transform: translateX(-50%);
	width: 100%;
	left: 50%;
	padding: 0 $bsu;

	img {
		max-width: 205px;
	}

	@include bp('medium') {
		img {
			max-width: 380px;
		}
	}
}

.c-hero-home__foot {
	position: absolute;
	top: auto;
	bottom: 0;
	padding-top: 0;
	background: url(/content/images/interface/bg/wg-bg-hero-wave.svg) top center no-repeat;
	background-size: cover;
	height: 90px;
	width: 100%;
}

.c-hero-home__promo-subheading {
	@include font-heading-12b;
}

.c-hero-home__promo-image-wrapper {
	flex: 0 0 50%;

	@include bp('large') {
		flex: 0 0 160px;
	}
}

.c-hero-home__promo {
	margin: auto;
	width: 80%;
	max-width: 350px;

	@include bp('large') {
		flex: 0 0 350px;
		align-items: center;
	}
}
